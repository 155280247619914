import React from "react";
import { PropTypes } from "prop-types";
import styled from "styled-components";

import { colors } from "Constants";
import { MAX_CONTENT_WIDTH } from "Constants";
import { PrimaryButton } from "components/shared/Buttons/Buttons2";

CTASection.propTypes = {
  background: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
};

function CTASection({ background, title, subtitle, buttonText }) {
  return (
    <Wrapper fill={background}>
      <Content>
        <Text>
          <Title>{title}</Title>
          <Subtitle>{subtitle}</Subtitle>
        </Text>
        <ButtonWrapper>
          <a href="https://app.photoinvoice.com/signup">
            <PrimaryButton>{buttonText || "Get Started Free"}</PrimaryButton>
          </a>
          <NoCC>No Credit Card Required</NoCC>
        </ButtonWrapper>
      </Content>
    </Wrapper>
  );
}

export default CTASection;

const Wrapper = styled.div`
  background-color: ${(props) => props.fill && colors.ltPrimaryBackground};
`;

const Content = styled.div`
  box-sizing: border-box;
  width: ${MAX_CONTENT_WIDTH};
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 90px 20px;

  @media (max-width: 800px) {
    padding: 20px 20px 40px;
  }

  @media (max-width: 640px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Text = styled.div`
  margin-right: 50px;
  font-size: 18px;

  @media (max-width: 650px) {
    margin-right: 0;
  }
`;

const Title = styled.h2`
  color: ${colors.primary};
  font-size: 36px;
  margin-top: 0;
  margin-bottom: 20px;

  @media (max-width: 860px) {
    font-size: 32px;
  }

  @media (max-width: 640px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.div`
  font-size: 17px;
  margin-top: -6px;
  line-height: 1.4;
  color: rgba(0, 0, 0, 0.8);
`;

const ButtonWrapper = styled.div`
  margin-left: 20px;

  @media (max-width: 640px) {
    margin-top: 30px;
  }
`;

const NoCC = styled.div`
  color: #6f6f6f;
  font-size: 12px;
  text-align: center;
  margin-top: 10px;
`;
