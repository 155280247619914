import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";

import heroImg from "assets/home/deliverable-unlocked.png";
import { PrimaryButton } from "components/shared/Buttons/Buttons2";

export default function HeroSection() {
  return (
    <Wrapper>
      <Title>
        <Line1>The easiest way for</Line1>
        <REP>Real Estate Photographers</REP>
        <Line2>
          to <Highlight>get paid upfront</Highlight>.
        </Line2>
      </Title>

      <SubTitle>
        <SubLine1>
          PhotoInvoice lets you create invoices that show watermarked samples,{" "}
        </SubLine1>
        but require payment to unlock downloads and links.
      </SubTitle>

      <Img
        initial={{ opacity: 0, scale: 0 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.3 }}
        src={heroImg}
      />

      <ButtonWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3 }}
      >
        <a href="https://app.photoinvoice.com/signup">
          <PrimaryButtonCustom>Get Started Free</PrimaryButtonCustom>
        </a>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  padding: 80px 20px;
  background-color: var(--primary);

  @media (max-width: 820px) {
    padding: 60px 20px;
  }
  @media (max-width: 640px) {
    padding: 40px 15px;
  }
`;

const Title = styled(motion.h1)`
  color: white;
  font-size: 68px;

  @media (max-width: 1000px) {
    font-size: 54px;
  }
  @media (max-width: 820px) {
    font-size: 42px;
  }
  @media (max-width: 640px) {
    font-size: 32px;
  }
  @media (max-width: 460px) {
    font-size: 28px;
  }
`;

const Line1 = styled.span`
  display: block;
`;

const REP = styled(motion.span)`
  display: block;
`;

const Line2 = styled.span`
  display: block;
`;

const Highlight = styled.span`
  color: var(--accentGreen);
`;

const SubTitle = styled.div`
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  line-height: 1.4;
  padding: 30px 20px 0;
  font-size: 22px;

  @media (max-width: 1000px) {
    font-size: 21px;
  }
  @media (max-width: 820px) {
    padding-top: 20px;
    font-size: 19px;
  }
  @media (max-width: 640px) {
    font-size: 17px;
  }
  @media (max-width: 460px) {
    font-size: 16px;
  }
`;

const SubLine1 = styled.span`
  display: block;

  @media (max-width: 720px) {
    display: inline;
  }
`;

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
`;

const PrimaryButtonCustom = styled(PrimaryButton)`
  @media (min-width: 680px) {
    height: 80px;
    padding: 0 50px;
    font-size: 24px;
    border-radius: 12px;
  }
`;

const Img = styled(motion.img)`
  display: block;
  width: 800px;
  max-width: 100%;
  margin: 30px auto;
`;
