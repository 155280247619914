import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { colors } from 'Constants'
import { MAX_CONTENT_WIDTH } from 'Constants'

Feature.propTypes = {
  direction: PropTypes.string, // "left" or "right"
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  image: PropTypes.string
}

function Feature({ title, children, image, direction }) {
  // applies shadow only if NOT a .png
  const shouldStylizeImg = img => {
    try {
      return !img.endsWith('.png') // can't use .jpg here, as small images are inline base64
    } catch (err) {
      return false
    }
  }

  return (
    <Wrapper direction={direction}>
      <Content direction={direction}>
        <Text>
          <Title>{title}</Title>
          <Body>{children}</Body>
        </Text>
        <ImageWrapper>
          <Image src={image} stylize={shouldStylizeImg(image)} />
        </ImageWrapper>
      </Content>
    </Wrapper>
  )
}

export default Feature

const Wrapper = styled.div`
  background-color: ${props =>
    props.direction === 'right' ? colors.ltBlueBackground : null};
  padding: 50px 0;
`

const Content = styled.div`
  width: ${MAX_CONTENT_WIDTH};
  max-width: 96%;
  margin: 0 auto;
  display: flex;
  flex-direction: ${props =>
    props.direction === 'right' ? 'row-reverse' : 'row'};

  @media (max-width: 900px) {
    flex-direction: column;
  }
`

const Text = styled.div`
  flex: 1.2;
  margin: 0 40px;
`

const Title = styled.h3`
  margin: 0;
  line-height: 1.4;
  color: ${colors.primary};
  font-size: 28px;

  @media (max-width: 900px) {
    text-align: center;
  }

  @media (max-width: 600px) {
    font-size: 24px;
  }
`

const Body = styled.div`
  margin-top: 30px;
  font-size: 17px;
  color: #333;
  line-height: 1.6;

  p {
    margin-bottom: 24px;
  }

  @media (max-width: 900px) {
    width: 600px;
    margin: 30px auto;
    max-width: 100%;
  }

  @media (max-width: 600px) {
    font-size: 16px;
  }
`

const ImageWrapper = styled.div`
  flex: 1;
  margin: 10px;
  padding: 0 30px;
`

const Image = styled.img`
  display: block;
  margin: 0 auto;
  width: 350px;
  max-width: 100%;
  border-radius: 10px;
  box-shadow: ${props =>
    props.stylize ? '0 2px 10px 0 rgba(0, 0, 0, 0.2)' : null};
`
