import React from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { HiX, HiCheck } from "react-icons/hi";
import { useInView } from "react-intersection-observer";

import Container from "components/shared/Container";
import invoiceListPaid from "assets/home/invoiceListPaid.png";
import invoiceListUnpaid from "assets/home/invoiceListUnpaid.png";
import arrow from "assets/home/arrow.png";

export default function FundamentallyDifferent() {
  const [arrowRef, isInView] = useInView();

  return (
    <Container>
      <Title>A fundamentally different invoicing experience</Title>
      <Sections>
        <SectionOther>
          <Header>
            <IconOther>
              <HiX />
            </IconOther>
            <SectionTitleOther>Other invoicing tools</SectionTitleOther>
          </Header>
          <Content>
            <Text>
              Deliver photos, videos, links, and the invoice at the same time...
              and hope for the best.
            </Text>
            <List>
              <li>
                <Emoji>⏳</Emoji> Payments are slow and often late
              </li>
              <li>
                <Emoji>💸</Emoji>You're waiting on thousands of dollars
              </li>
              <li>
                <Emoji>😭</Emoji>That one client just won't pay
              </li>
            </List>
          </Content>
          <Img src={invoiceListUnpaid} />
        </SectionOther>
        <SectionPI>
          <Header>
            <IconPI>
              <HiCheck />
            </IconPI>
            <SectionTitlePI>PhotoInvoice</SectionTitlePI>
          </Header>
          <Content>
            <Text>
              Show watermarked samples, but downloads & links are locked until
              payment is received.
            </Text>
            <List>
              <li>
                <Emoji>⚡</Emoji>Clients pay on time, every time
              </li>
              <li>
                <Emoji>✅</Emoji>No late payments to track down
              </li>
              <li>
                <Emoji>🏖️</Emoji>No messy invoice list or reminders to send
              </li>
            </List>
          </Content>
          <ImgWrapper>
            <Arrow
              ref={arrowRef}
              src={arrow}
              intial={{ x: -200 }}
              animate={{ x: isInView ? 0 : -200 }}
            />

            <Img src={invoiceListPaid} />
          </ImgWrapper>
        </SectionPI>
      </Sections>
    </Container>
  );
}

const otherColor = "#e21102";

const Title = styled.h2`
  text-align: center;
  margin-top: 100px;
  margin-bottom: 60px;

  @media (max-width: 1000px) {
    font-size: 38px;
  }
  @media (max-width: 820px) {
    font-size: 32px;
    margin-bottom: 40px;
  }
  @media (max-width: 640px) {
    font-size: 24px;
    margin-bottom: 30px;
  }
  @media (max-width: 460px) {
    font-size: 21px;
  }
`;

const Sections = styled.div`
  display: flex;
  margin: 0 -20px 60px;

  @media (max-width: 650px) {
    flex-direction: column;
    margin: 0 0 40px;
  }
`;

const Section = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  jusify-content: space-between;
  background: white;
  border-radius: 18px;
  margin: 0 20px;
  padding: 40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.05);

  @media (max-width: 900px) {
    padding: 30px;
  }

  @media (max-width: 700px) {
    padding: 20px;
    margin: 0 10px;
  }
`;

const SectionOther = styled(Section)`
  @media (max-width: 680px) {
    margin-bottom: 20px;
  }
`;

const SectionPI = styled(Section)`
  border: 2px solid var(--accentGreen);
`;

const Icon = styled.div`
  height: 100px;
  width: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: white;
  border-radius: 100%;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 830px) {
    font-size: 38px;
  }

  @media (max-width: 600px) {
    height: 80px;
    width: 80px;
  }
`;

const IconOther = styled(Icon)`
  background-color: ${otherColor};
`;
const IconPI = styled(Icon)`
  background: var(--accentGreen);
`;

const SectionTitle = styled.div`
  text-align: center;
  margin-top: 20px;
  padding: 10px 40px;
  border-radius: 20px;
  color: white;
  font-size: 21px;
  font-weight: 600;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);

  @media (max-width: 830px) {
    font-size: 18px;
  }
`;

const SectionTitleOther = styled(SectionTitle)`
  background-color: ${otherColor};
`;
const SectionTitlePI = styled(SectionTitle)`
  background: var(--accentGreen);
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Content = styled.div`
  flex: 1;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;

  @media (max-width: 850px) {
    font-size: 17px;
  }

  @media (max-width: 480px) {
    font-size: 16px;
  }
`;

const Text = styled.div`
  @media (max-width: 1000px) {
    text-align: center;
  }

  @media (max-width: 680px) {
    text-align: left;
  }
`;

const List = styled.ul`
  margin-top: 30px;
  list-style: none;
  text-align: left;
  /* font-size: 19px; */

  li {
    margin: 10px 0 10px 30px;
    display: flex;

    @media (max-width: 1000px) {
      margin-left: 0;
    }

    @media (max-width: 680px) {
      margin-left: 30px;
    }

    @media (max-width: 480px) {
      margin-left: 0;
    }
  }
`;

const Emoji = styled.span`
  display: block;
  margin-right: 10px;
`;

const Img = styled.img`
  max-width: 100%;
  margin-top: 40px;
`;

const ImgWrapper = styled.div`
  position: relative;
`;

const Arrow = styled(motion.img)`
  position: absolute;
  top: 40%;
  left: -175px;
  width: 200px;

  @media (max-width: 1000px) {
    width: 170px;
    left: -150px;
  }

  @media (max-width: 850px) {
    width: 140px;
    left: -120px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;
